import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Media,
    Row,
    Table,
  } from "reactstrap";
  // core components
  import Dialog from "@material-ui/core/Dialog";
  import DialogActions from "@material-ui/core/DialogActions";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogTitle from "@material-ui/core/DialogTitle";
  import Tooltip from "@material-ui/core/Tooltip";
  import MuiAlert from "@mui/material/Alert";
  import Snackbar from "@mui/material/Snackbar";
  import ChatHeader from "components/Headers/ChatHeader.js";
  import { AuthContext } from "context/AuthContext";
  import moment from "moment";
  import React, { useContext, useEffect, useState } from "react";
  import ReactLoading from "react-loading";
  import ReactPaginate from "react-paginate";
  import { useHistory } from "react-router-dom";
  import {
    changePurchaseStatus,
    getAllPaginatedPurchases,
  } from "services/PurchaseServices";
  import { computeNumerotation } from "../../constants";
import { getAllPaginatedApplications } from "services/JobServices";
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const JobApplications = (props) => {
    const { user } = useContext(AuthContext);
    const [copiedText, setCopiedText] = useState();
    const [jobApplications, setJobApplications] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeObject, setActiveObject] = useState(null);
    const [nbPageElement, setNbPageElement] = useState(0);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(25);
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();
  
    const handleOpenAlert = () => {
      setOpenAlert(true);
    };
  
    const handleCloseAlert = () => {
      setOpenAlert(false);
    };
  
    const handleStatusModal = () => {
      setOpenStatusModal(!openStatusModal);
    };
  
    useEffect(() => {
      const retrieveJobApplications = async () => {
        let response = await getAllPaginatedApplications();
        console.log(response.data);
        setJobApplications(response.data);
        setLastPage(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
        setLoading(false);
      };
  
      retrieveJobApplications();
    }, []);
  
    const retrieveJobApplications = async () => {
      let response = await getAllPaginatedApplications();
      console.log(response.data);
      setJobApplications(response.data);
      setLastPage(response.meta.last_page);
      setCurrentPage(response.meta.current_page);
    };
  
    const handlePageClick = async (data) => {
      const requestedPage = data.selected + 1;
      let response = await getAllPaginatedApplications(
        parseInt(requestedPage),
        itemPerPage
      );
      setJobApplications(response.data);
      //setLastPage(response.meta.last_page);
      setCurrentPage(response.meta.current_page);
    };
  
    const handleSearch = async (e) => {
      let nb = e.target.value;
      setItemPerPage(nb);
      setNbPageElement(parseInt(nb));
      let response = await getAllPaginatedApplications(undefined, parseInt(nb));
      console.log(response.data);
      setJobApplications(response.data);
      setLastPage(response.meta.last_page);
      setCurrentPage(response.meta.current_page);
    };
  
    const goToJobApplications = (e) => {
      let job_id = parseInt(e.target.getAttribute("value"));
  
      props.history.push({
        pathname: "/admin/single-job-applications",
        search: "?jobId=" + job_id,
      });
    };
  
    const handleChangeStatus = () => {
      handleStatusModal();
    };
  
    const changePostStatus = async () => {
      let purchase_id = activeObject.item.id;
      setOpenStatusModal(false);
  
      try {
        await changePurchaseStatus(purchase_id);
        retrieveJobApplications();
        setAlertMessage("Feature value added sucessfully");
        setAlertType("success");
        handleOpenAlert();
      } catch (error) {
        setAlertMessage(error.message);
        setAlertType("error");
        handleOpenAlert();
      }
    };
  
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alertType}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={openStatusModal}
          onClose={handleStatusModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Status</DialogTitle>
          <DialogContent>
            <CardBody>
              <div className="pl-lg-4">
                <Row>
                  <Col md="12">
                    <p>Confirm that you want to diliver this transaction ?</p>
                    <FormGroup></FormGroup>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleStatusModal} color="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={changePostStatus} color="primary">
              Change
            </Button>
          </DialogActions>
        </Dialog>
        <ChatHeader />
        {/* Page content */}
  
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">All job applications here ({jobApplications.length})</h3>
                </CardHeader>
                {isLoading ? (
                  <ReactLoading type={"spin"} color="#B20101" />
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Job Title</th>
                        <th scope="col">Applier Name</th>
                        <th scope="col">Apllier Email</th>
                        <th scope="col">Apllier Phone</th>
                        <th scope="col">Number of Files</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobApplications?.map((item, index) => (
                        <>
                          <tr key={item.id}>
                            <th scope="row">
                              <span className="mb-0 text-sm">
                                {computeNumerotation(
                                  currentPage,
                                  itemPerPage,
                                  index
                                )}
                              </span>
                            </th>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {item.job_title}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <th scope="row">{item.first_name + " " + item.last_name}</th>
                            <th scope="row">{item.email}</th>
                            <th scope="row">{item.phone}</th>
                            <th scope="row">{item.files.length}</th>
                            <td>
                              {moment(item.created_at).format(
                                "YYYY/MM/DD kk:mm:ss"
                              )}
                            </td>
                            <td className="text-right">
                              <div className="d-flex px-2 py-1">
                                <Tooltip title="View details" placement="top">
                                  <span
                                    className="material-icons danger"
                                    value={item.id}
                                    onClick={(e) => {
                                      setActiveObject({ item });
                                      goToJobApplications(e);
                                    }}
                                    style={{ cursor: "pointer", color: "blue" }}
                                  >
                                    visibility
                                  </span>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                )}
                <CardFooter className="py-4">
                  <Row>
                    <Col>
                      <Form>
                        <FormGroup className="mb-0" style={{ width: "100px" }}>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25" selected>
                              25
                            </option>
                          </select>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col>
                      <nav aria-label="...">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={lastPage}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination justify-content-end mb-0"
                          }
                          pageClassName={"page-item"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          pageLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
  
  export default JobApplications;
  