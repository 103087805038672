import axios from "axios";


export function getActualites() {
    return new Promise((resolve, reject) => {
        axios
            .get('/get/actualites')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function getPaginatedActualites(id, nb_of_element) {
    let data = {numberElement: nb_of_element}
    if(id !== undefined){
        return new Promise((resolve, reject) => {
            axios
                .post('/actualites/paginate?page='+`${id}`, data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        }); }else {
        return new Promise((resolve, reject) => {
            axios
                .post('/actualites/paginate', data)
                .then( response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    }
}


export const createActualites = (data, images, actualiteFiles, updateProgress) => {

    let actualiteFormData = new FormData();

    for (const img of images) {
        actualiteFormData.append("images[]", img.file);
    }

    for (const file of actualiteFiles) {
        actualiteFormData.append("files[]", file.file);
    }

    actualiteFormData.append("sub_category", data.sub_category);

    actualiteFormData.append("name", data.name);
    actualiteFormData.append("short_description", data.short_description);
    actualiteFormData.append("long_description", data.long_description);
    actualiteFormData.append("tags", data.tag);
    actualiteFormData.append("quotation", data.quotation);
    actualiteFormData.append("quotation_owner", data.quotation_owner);

    const options = {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
                updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/create/actualite`, actualiteFormData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                updateProgress(100);
                console.log(response.data.data);
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export const updateActualite = (data, currentArticle, actualite_id, images, actualiteFiles, toDeleteImages, toDeleteFiles, updateProgress) => {

    let articleFormData = new FormData();

    for (const img of images) {
        articleFormData.append("images[]", img.file);
    }

    for (const file of actualiteFiles) {
        articleFormData.append("files[]", file.file);
    }

    articleFormData.append("sub_category", data.sub_category);

    articleFormData.append("name", data.name);
    articleFormData.append("short_description", data.short_description);
    articleFormData.append("long_description", data.long_description);
    articleFormData.append("to_delete_img", toDeleteImages);
    articleFormData.append("to_delete_fil", toDeleteFiles);
    articleFormData.append("tags", data.tag);
    articleFormData.append("quotation", data.quotation);
    articleFormData.append("quotation_owner", data.quotation_owner);

    const options = {
        onUploadProgress: (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);

            if (percent < 100) {
                updateProgress(percent);
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`/update/actualite/${actualite_id}`, articleFormData, options, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                updateProgress(100);
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export function deleteNews(post_id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/actualite/${post_id}`)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });

}